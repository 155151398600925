.flash {
  animation-name: flash;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

@keyframes flash {
  0% {
    background-color: white;
    animation-fill-mode: backwards;
  }
  50% {
    background-color: yellow;
  }
  75% {
    background-color: orange;
  }
  100% {
    background-color: white;
  }
}